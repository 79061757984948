import React, { Fragment, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import Routes from "./Routes";
import "./App.css";
import languageConfig from "./languageConfig";
import LoaderButton from "./components/LoaderButton";

export function App(childProps) {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [userGroupPremium, setUserGroupPremium] = useState(null);
	const [isGettingLang, setIsGettingLang] = useState(true);
	const [userLang, setUserLang] = useState("eng");
	const [configLang, setConfigLang] = useState(null); // languageConfig("eng");
	const [isArabicOn, setIsArabicOn] = useState(false);
	const [cognitoId, setCognitoId] = useState(null);

	const [user, setUser] = useState(null);
	const [isUserRequested, setIsUserRequested] = useState(false);
	const [isUserRequestSuccessful, setIsUserRequestSuccessful] = useState(false);
	const [countUserRequests, setCountUserRequests] = useState(0);
	const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

	function setTheUserConfigLanguage() {
		if (isGettingLang) {
			try {
				if (isArabicOn) {
					// sessionVariable.idToken.payload["custom:airports"] && sessionVariable.idToken.payload["custom:airports"].includes("BWI") == true) {
					setConfigLang(languageConfig("arb"));
					// setUserLang("arb");
				} else {
					setConfigLang(languageConfig("eng"));
					// setUserLang("eng");
				}
			} catch (e) {
				setConfigLang(languageConfig("eng"));
				// setUserLang("eng");
			}
			setIsGettingLang(false);
		} else {
			if (isArabicOn) {
				// sessionVariable.idToken.payload["custom:airports"] && sessionVariable.idToken.payload["custom:airports"].includes("BWI") == true) {
				setConfigLang(languageConfig("arb"));
				// setUserLang("arb");
			} else {
				setConfigLang(languageConfig("eng"));
				// setUserLang("eng");
			}
			setIsGettingLang(false);
		}
	}

	useEffect(() => {
		// Auth.currentSession is probably what needs to be used in Home.js to obtain jwt token
		if (isAuthenticating) {
			Auth.currentSession()
				.then(function (sessionVariable) {
					// Get user auth
					Auth.currentAuthenticatedUser()
						.then(function (user) {
							setUser(user);
							setIsUserRequested(true);
							setIsUserRequestSuccessful(true);
							setCountUserRequests(countUserRequests + 1);
						})
						.catch(function (e) {
							setIsUserRequested(false);
							setIsUserRequestSuccessful(false);
							setCountUserRequests(countUserRequests + 1);
						});

					// set jwt token
					try {
						setCognitoId(sessionVariable.idToken.payload.sub);
					} catch (e) {
						console.log("failed to set auth token. error: " + e);
					}
					// set user groups
					try {
						if (
							sessionVariable.idToken.payload["cognito:groups"] &&
							sessionVariable.idToken.payload["cognito:groups"].indexOf("premium") > -1
						) {
							setUserGroupPremium(true);
						} else {
							setUserGroupPremium(false);
						}
					} catch (e) {
						console.log("ERROR getting user groups: " + e);
						setUserGroupPremium(false);
					}

					// set user lang
					if (isGettingLang) {
						setTheUserConfigLanguage();
					}

					// set user session and isAuthed
					childProps.sessionVariable = sessionVariable;
					setIsAuthenticated(true);
					setIsAuthenticating(false);
				})
				.catch(function (e) {
					// catch for when there is no current session (not logged in)
					try {
						if (isGettingLang) {
							setTheUserConfigLanguage();
							return;
						}
					} catch (e) {
						console.log("appjs useEffect failed to set config lang: " + e);
					}
					if (e !== "No current user") {
						alert(e);
					}
					setIsAuthenticating(false);
				});
		} else {
			if (isGettingLang) {
				setTheUserConfigLanguage();
				return;
			}
		}
	}, [isAuthenticating, isGettingLang]); // isAuthenticating, isGettingLang, user

	function userHasAuthenticated(authenticated) {
		setIsAuthenticated(authenticated);
	}

	function presentLoadingUi() {
		let buttonProps = {
			isLoading: true,
			text: "sometext",
			loadingText: "loadingText",
		};
		return (
			<LoaderButton
				block
				bsSize="large"
				disabled={false}
				type="submit"
				isLoading={true}
				text="Loading"
				loadingText=""
			/>
		);
	}

	const handleSwitchChange = (name) => (event) => {
		setIsGettingLang(true);
		setIsArabicOn(event.target.checked);
	};

	childProps = {
		isAuthenticated: isAuthenticated,
		userHasAuthenticated: userHasAuthenticated,
		setIsAuthenticated: setIsAuthenticated,
		userGroupPremium: userGroupPremium,
		configLang: configLang,
		cognitoId: cognitoId,
		user: user,
	};

	async function handleLogout() {
		await Auth.signOut();

		setIsAuthenticated(false);

		window.location.href = "/"
	}

	if (!isAuthenticating) {
		return (
			<div className="App container">
				<nav className="navbar navbar-expand-lg navbar-light bg-light">
					<a className="navbar-brand" href="/">
						<img src="mstile-150x150.png" height="30" width="30" rounded />
						&nbsp;Flylert
					</a>
					<div className="">
						<button type="button" onClick={() => setIsNavbarExpanded(!isNavbarExpanded)}>
							<span className="navbar-toggler-icon"></span>
						</button>
						<br />
					</div>
				</nav>
				<div>
				{
					isNavbarExpanded ? (
						isAuthenticated && !isGettingLang ?
						(
							<div className="Navbar text-center">
								<div className="linkDiv">
									<a className="btn btn-lg btn-block btn-light" href="/dashboard">
										Dashboard
									</a>
								</div>
								{/* <div className="linkDiv">
									<a className="btn btn-lg btn-block btn-light" href="/deals">
										Deals
									</a>
								</div> */}
								<div className="linkDiv">
									<a className="btn btn-lg btn-block btn-light" href="/blog">
										Blog
									</a>
								</div>
								<div className="linkDiv">
									<a className="btn btn-lg btn-block btn-light" href="#" onClick={() => handleLogout()}>
										Logout
									</a>
								</div>
							</div>
						)
						:
						(
							<>
								{/** Unauthenticated */}
								<div className="Navbar text-center">
									<div className="linkDiv">
										<a className="btn btn-lg btn-block btn-light" href="/login">
											Login
										</a>
									</div>
									<div className="linkDiv">
										<a className="btn btn-lg btn-block btn-light" href="/signup">
											Signup
										</a>
									</div>
									<div className="linkDiv">
										<a className="btn btn-lg btn-block btn-light" href="/blog">
											Blog
										</a>
									</div>
								</div>
							</>
						)
					)
					: (
						<div>
						</div>
					)
				}
				</div>


				{/* <form className="form-inline my-2 my-lg-0">
			<input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
			<button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
		</form> */}
				<Routes childProps={childProps} />
			</div>
		);
	} else {
		return presentLoadingUi();
	}
}

export default withRouter(App);
