const dev = {
	STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
	s3: {
		REGION: "us-east-1",
		BUCKET: "notes-app-2-api-dev-attachmentsbucket-6wbhcogxihbo",
	},
	apiGateway: {
		// REGION: "us-east-1",
		// URL: "https://api.serverless-stack.seed-demo.club/dev"
		REGION: "us-east-1",
		URL: "https://hxbcj0nb8i.execute-api.us-east-1.amazonaws.com/prd",
	},
	cognito: {
		// REGION: "us-east-1",
		// USER_POOL_ID: "us-east-1_yKOQs2dWK",
		// APP_CLIENT_ID: "1h0r8s763vnqj4id6dvguanp8f",
		// IDENTITY_POOL_ID: "us-east-1:dedfd34a-9d7e-4bf2-a1dd-ef603bac2ecb"
		REGION: "us-east-1",
		USER_POOL_ID: "us-east-1_dBhR56CDg",
		APP_CLIENT_ID: "525v6ts2rnbhmtf8e3p5hovtj3",
		IDENTITY_POOL_ID: "us-east-1:dedfd34a-9d7e-4bf2-a1dd-ef603bac2ecb",
	},
	common: {
		appName: "Flylert",
		tagline: "Stopping airfare ripoffs",
		// tagline: "Simple flight alerts for price drops"
	},
	links: {
		register: "https://c86w0xf810.execute-api.us-east-1.amazonaws.com/demo/register",
		unsubscribe: "https://c86w0xf810.execute-api.us-east-1.amazonaws.com/demo/unsubscribe",
		airportList: "https://c86w0xf810.execute-api.us-east-1.amazonaws.com/demo/originAirports",
		testData: "https://c86w0xf810.execute-api.us-east-1.amazonaws.com/demo/smartDeals",
		cheapestDeals: "https://c86w0xf810.execute-api.us-east-1.amazonaws.com/demo/cheapDeals",
		redirect: "https://c86w0xf810.execute-api.us-east-1.amazonaws.com/demo/flylnkredir", // "https://hxbcj0nb8i.execute-api.us-east-1.amazonaws.com/prd/flylnkredir",
		login: "/login",
		dashboard: "/",
		settings: "/settings",
		smartestDeals: "/smartestDeals",
		clickDeals: "/clickDeals",
		cheapestDeals: "/cheapestDeals",
		paymentInfo: "/paymentInfo",
		login: "/login",
		signup: "/signup",
		flightClick: "/flight-click",
	},
	userPoolData: {
		UserPoolId: "us-east-1_dBhR56CDg", // Your user pool id here - 'us-east-1_dBhR56CDg'
		ClientId: "525v6ts2rnbhmtf8e3p5hovtj3", // Your application client id here - '525v6ts2rnbhmtf8e3p5hovtj3' - REMEMBER: create the client without a secret
	},
	memberships: [
		{
			key: 1,
			id: 1,
			price: "5",
			title: "Bronze",
			description: [
				"Email without ads in between flight prices",
				"Access email deals before basic users",
				"Get more than three emails per week",
			],
		},
		{
			key: 2,
			id: 2,
			price: "20",
			title: "Silver",
			description: [
				"Vote on feature suggestions of Platinum Members",
				"Join private discussion group when released",
			],
		},
		{
			key: 3,
			id: 3,
			price: "50",
			title: "Gold",
			description: ["First time members get a Flylert hat", "Request specific destinations"],
		},
		{
			key: 4,
			id: 4,
			price: "100",
			title: "Platinum",
			description: [
				"Submit a new feature suggestion for a vote",
				"First time members get a Flylert t-shirt",
			],
		},
		{
			key: 5,
			id: 5,
			price: "200",
			title: "Business Class",
			description: ["Advertise in the basic user email", "Utilize our bitcoin payment software"],
		},
		{
			key: 6,
			id: 6,
			price: "500",
			title: "Supersonic",
			description: ["Forthcoming features"],
		},
		{
			key: 7,
			id: 7,
			price: "1,000",
			title: "Captain's Club",
			description: ["Forthcoming features"],
		},
		{
			key: 8,
			id: 8,
			price: "5,000",
			title: "Founding Club",
			description: ["Forthcoming features"],
		},
		{
			key: 9,
			id: 9,
			price: "10,000",
			title: "Universe",
			description: ["Forthcoming features"],
		},
		{
			key: 10,
			id: 10,
			price: "20,000",
			title: "Multiverse",
			description: ["Forthcoming features"],
		},
	],
};

const prod = {
	STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
	s3: {
		REGION: "us-east-1",
		BUCKET: "notes-app-2-api-prod-attachmentsbucket-1h5n5ttet1hy0",
	},
	apiGateway: {
		REGION: "us-east-1",
		URL: "https://hxbcj0nb8i.execute-api.us-east-1.amazonaws.com/prd",
	},
	cognito: {
		// REGION: "us-east-1",
		// USER_POOL_ID: "us-east-1_yKOQs2dWK",
		// APP_CLIENT_ID: "1h0r8s763vnqj4id6dvguanp8f",
		// IDENTITY_POOL_ID: "us-east-1:dedfd34a-9d7e-4bf2-a1dd-ef603bac2ecb"
		REGION: "us-east-1",
		USER_POOL_ID: "us-east-1_dBhR56CDg",
		APP_CLIENT_ID: "525v6ts2rnbhmtf8e3p5hovtj3",
		IDENTITY_POOL_ID: "us-east-1:dedfd34a-9d7e-4bf2-a1dd-ef603bac2ecb",
	},
	common: {
		appName: "Flylert",
		tagline: "Simple flight alerts on price drop",
	},
	links: {
		register: "https://hxbcj0nb8i.execute-api.us-east-1.amazonaws.com/prd/register",
		unsubscribe: "https://hxbcj0nb8i.execute-api.us-east-1.amazonaws.com/prd/unsubscribe",
		airportList: "https://hxbcj0nb8i.execute-api.us-east-1.amazonaws.com/prd/originAirports",
		testData: "https://hxbcj0nb8i.execute-api.us-east-1.amazonaws.com/prd/smartDeals0",
		cheapestDeals: "https://hxbcj0nb8i.execute-api.us-east-1.amazonaws.com/prd/cheapDeals",
		redirect: "https://hxbcj0nb8i.execute-api.us-east-1.amazonaws.com/prd/flylnkredir", // "https://hxbcj0nb8i.execute-api.us-east-1.amazonaws.com/prd/flylnkredir",
		login: "/login",
		dashboard: "/",
		settings: "/settings",
		smartestDeals: "/smartestDeals",
		clickDeals: "/clickDeals",
		cheapestDeals: "/cheapestDeals",
		paymentInfo: "/paymentInfo",
		login: "/login",
		signup: "/signup",
		flightClick: "/flight-click",
	},
	userPoolData: {
		UserPoolId: "us-east-1_dBhR56CDg", // Your user pool id here - 'us-east-1_dBhR56CDg'
		ClientId: "525v6ts2rnbhmtf8e3p5hovtj3", // Your application client id here - '525v6ts2rnbhmtf8e3p5hovtj3' - REMEMBER: create the client without a secret
	},
	memberships: dev.memberships,
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
	// Add common config values here
	MAX_ATTACHMENT_SIZE: 5000000,
	...config,
};
