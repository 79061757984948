import DealsPage from "../components/DealsPage";


//
export default function ClickDeals(props) {
	// page customization variables
	const dealType = "clicks";
	let pageHeader;
	let pageDescription;
	if (props.configLang) {
		pageHeader = props.configLang.pageTitles.clickDeals;
		pageDescription = props.configLang.descriptions.clickDeals;
	} else {
		pageHeader = "Recent Clicks";
		pageDescription = [{ text: "These are the flights that you've recently clicked on." }];
	}

	// RETURN the UI from common DealsPage
	return DealsPage(props, dealType, pageHeader, pageDescription);
}
