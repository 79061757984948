import DealsPage from "../components/DealsPage";

//
export default function AllSmartDeals(props) {
	// page customization variables
	const dealType = "allsmart";
	let pageHeader;
	let pageDescription;

	if (props.configLang) {
		pageHeader = props.configLang.pageTitles.allSmartFlights;
		pageDescription = props.configLang.descriptions.allSmartFlights;
	} else {
		pageHeader = "All Smart Flights";
		pageDescription = [
			{
				text:
					"These are the flight deals that we think are the best based on historical data for ALL AIRPORT ORIGINS....",
			},
			{
				text:
					'"Region Rank" indicates that trip\'s price rank from that origin to other destinations in that region - 1 being the best.',
			},
		];
	}
	// RETURN the UI from common DealsPage
	return DealsPage(props, dealType, pageHeader, pageDescription);
}
