// do not import useState here
import { API, Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
// import { useEndpoint } from './useEndpoint';

// I would think that this just needs to handle the data that comes back from useEndpoint and send it on back to Home.js - or should this function contain the logic in useEndpoint?
export function useGetFlightHook(props) {
	// the useState way
	const [flights, setFlights] = useState(null);
	const [isApiRequested, setIsApiRequested] = useState(false);
	const [isApiRequestSuccessful, setIsApiRequestSuccessful] = useState(false);
	const [countApiRequests, setCountApiRequests] = useState(0);
	const [isUserMissingAirports, setIsUserMissingAirports] = useState(false);
	const [isUserAuthorized, setIsUserAuthorized] = useState(true);

	useEffect(() => {
		function handleDataChange(res) {
			setFlights(res);
		}
		// check authentication
		if (!props.isAuthenticated) {
			return;
		}

		// Get user auth and get deals
		Auth.currentAuthenticatedUser()
			.then(async (user) => {
				// Get user airports with cache just in case of failure
				let airports = [];
				airports = user.attributes["custom:airports"].split(",");

				// bypass cache and get airports
				try {
					const promise = new Promise((resolve, reject) => {
						user.getUserData(
							(err, userData) => {
								if (err) {
									alert(err.message || JSON.stringify(err));
									resolve();
								}
								airports = userData.UserAttributes[2].Value.split(",");
								resolve();
							},
							{ bypassCache: true },
						);
					});
					const result = await promise;
					const airportParams = [];
					for (let i = 0; i < 5; i++) {
						const pushValue = airports[i] || "null";
						airportParams.push(pushValue);
					}

					// Set deal request params
					const apiName = "smartDeals";
					const path = "/smartDeals0";
					const myInit = {
						// OPTIONAL
						body: {
							airportCode1: airportParams[0],
							airportCode2: airportParams[1],
							airportCode3: airportParams[2],
							airportCode4: airportParams[3],
							airportCode5: airportParams[4],
							dealType: props.dealType,
							uuid: props.uuid || null,
						}, // replace this with attributes you need
						headers: {
							Authorization: user.signInUserSession.idToken.jwtToken,
						}, // OPTIONAL
					};

					// if api not requested too many times, make request
					const maxReqTries = 3;
					if (!isApiRequested && countApiRequests < maxReqTries) {
						API.post(apiName, path, myInit)
							.then((res) => {
								setIsApiRequested(true);
								handleDataChange(res);
								setIsApiRequestSuccessful(true);
							})
							.catch((e) => {
								console.log(`ERROR: ${JSON.stringify(e)}`);
								if (e && e.response && e.response.status && e.response.status == 403) {
									setCountApiRequests(maxReqTries);
									setIsApiRequested(true);
									setIsUserAuthorized(false);
								} else {
									setCountApiRequests(countApiRequests + 1);
									if (countApiRequests >= maxReqTries) {
										setIsApiRequested(true);
									}
								}
							});
					}
				} catch (e) {
					console.log(`error getting user data: ${e}`);
				}
			})
			.catch((e) => {
				console.log("Problem obtaining user authentication - probably empty airports");
				setIsUserMissingAirports(true);
				// window.location.href = config.links.settings;
			});
	}, []);

	return {
		isApiRequested,
		isApiRequestSuccessful,
		flights,
		isUserMissingAirports,
		isUserAuthorized,
	};
}
