import React from "react";
import "./NotFound.css";

export default () => (
	<div>
		<ul>
			<li>
				<h3>Smart Deals returns too many </h3>
				<p>
					On the first load, smart deals will load too many flights, but if you wait ~20sec and then
					load it again, the correct number shows up{" "}
				</p>
			</li>
			<li>
				<h3>Bug list needs to allow testers to submit a bug </h3>
				<p>Duh </p>
			</li>

			{/*<h3> </h3>
    <p>Duh </p>

    <h3> </h3>
    <p>Duh </p>

    <h3> </h3>
    <p>Duh </p>*/}
		</ul>
	</div>
);
