const blogPosts = [
	{
		title: "Summer in Colorado",
		description: "Mountain ski lifts are open, but for biking and hiking instead of...",
		author: "Carson J",
		date: "Dec 20, 2020",
		imgUrl: "z-co-1.jpg",
		url: "/1",
	},
	{
		title: "Puerto Rico: domestic escape",
		description:
			"This Carribean island has beaches, caves, and mountains over 5,000 feet in elevation...",
		author: "Thomas R",
		date: "Dec 13, 2020",
		imgUrl: "z-pr-1.jpg",
		url: "/2",
	},
	{
		title: "Mexico: Cancun & Tulum",
		description:
			"While resorts can be as low as $100 / night, my hostel only $13 / night and was superb...",
		author: "David H",
		date: "Dec 6, 2020",
		imgUrl: "z-mx-1.jpg",
		url: "/3",
	},
	{
		title: "Bend, Oregon: the high desert",
		description:
			"This part of Oregon is unlike Portland, Salem, and Eugene. A better comparison is Colorado...",
		author: "Tiffany L",
		date: "Nov 30, 2020",
		imgUrl: "z-or-1.jpg",
		url: "/4",
	},
	{
		title: "St. Geroge, UT: National Parks Abound",
		description:
			"This small town is just north of Arizona and is less than 30 mins from Zion Natl Park and less than...",
		author: "Anna H",
		date: "Nov 23, 2020",
		imgUrl: "z-ut-1.jpg",
		url: "/5",
	},
];

module.exports = {
	blogPosts,
};
