import DealsPage from "../components/DealsPage";
import React, { useState, useEffect } from "react";
import "./Home.css";
import config from "../config.js";

export default function Home(props) {
	const dealType = "region";
	let pageHeader;
	let pageDescription;
	let memberships = config.memberships;

	const parseMembershipGroups = (props) => {
		if (props.user.signInUserSession.idToken.payload["cognito:groups"]) {
			let userLevel = "";
			props.user.signInUserSession.idToken.payload["cognito:groups"].forEach((group) => {
				if (group.includes("level")) {
					userLevel = group.split("level")[1];
				}
			});
			return memberships[userLevel - 1].title;
		} else {
			return "Basic";
		}
	};

	const getMembershipInfo = (props) => {
		return (
			<div>
				<h3>My Membership Level</h3>
				<p>{parseMembershipGroups(props)}</p>
				<p>Memberships are invite-only</p>
				{/* <a href="/memberships" className="linkBtn btn btn-secondary btn-block">
					Upgrade Membership
				</a> */}
				<br />
			</div>
		);
	};

	const getAirportInfo = (props) => {
		return (
			<div>
				<h3>My Airports</h3>
				<p>{props.user.signInUserSession.idToken.payload["custom:airports"]}</p>
				<a href="/airports" className="linkBtn btn btn-secondary btn-block">
					Change Airports
				</a>
				<br />
			</div>
		);
	};

	const getDealInfo = () => {
		return (
			<div>
				<h3>Recently Viewed Deals</h3>
				<p>Shown to Gold members only</p>
				<br />
			</div>
		);
	};

	const getBlogInfo = () => {
		return (
			<div>
				<h3>Founder Dave</h3>
				<p>
					Hi, I'm Dave. I founded this traveler's community. After making 30+ trips from 2015-2017,
					I began work on a travel email that would alert my friends to the best deals I found. I
					felt like my experience traveling all over the US and internationally and my desire to
					make a trip out of wherever I was would provide value to my readers who wanted to make big
					trips affordably.
				</p>
				{/* <p>
					I focused my email on some of my favorite destinations: Western US States with National 
					Parks. I mainly just wanted to keep an eye out for cheap flights to places where I could hike 
					and camp. I made several trips to Colorado over the next few years when deals would strike. My roommate at the time 
					said "Hey Dave, you should add The Caribbean." He traveled there several times before we formed a business 
					partnership and scheduled our company retreat in the Dominican Republic.
				</p> */}
				<p>
					The positive response to this service has been overwhelming. Users comment not only on the
					amazing flight deals but also the simplicity of the email and website.
				</p>
				<p>
					Joining this email connects you to a community of travelers who know how to avoid costly
					pitfalls of travel with baggage, rebooking, lodging, rental cars, or problems like I
					nearly had if not for one of my members who advised me on how to get a new passport when
					mine was stolen a week before an international flight - advice that saved me nearly a
					thousand dollars.
				</p>
				<p>
					Help me to make this my full time endeavor. Tell as many people as you can and help me to
					grow this community.
				</p>
				{/* <p>
				Be the first to access our forthcoming private
					discussion group and get the chance to see deals that only our exclusive users see by
					enrolling as a monthly supporting member.
				</p> */}
				{/* <a href="/memberships" className="linkBtn btn btn-secondary btn-block">Get a Membership</a> */}
				<br />
			</div>
		);
	};

	return props && props.user && props.user.signInUserSession ? (
		<div className="Settings text-center">
			<br />
			{getAirportInfo(props)}

			{getMembershipInfo(props)}

			{getDealInfo()}

			{getBlogInfo()}
		</div>
	) : (
		DealsPage(props, dealType, pageHeader, pageDescription)
	);
}
