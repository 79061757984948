import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { CardElement, injectStripe } from "react-stripe-elements";
import LoaderButton from "./LoaderButton";
import "./HomePageLoggedOut.css";
import Login from "../containers/Login.js";

export default function HomePageLoggedOut(props, langConfig, config, loading) {
	const [isReadMore, setIsReadMore] = useState(false);

	const handleReadLessOrMore = (event) => {
		setIsReadMore(!isReadMore);
	};

	if (langConfig) {
		return (
			<div className="lander">
				<br />
				<p>
					emails packed with <b>cheap adventure flights</b> to destinations around the globe
				</p>
				<Login />
				<br />
				<div className="greeting">
					<div>
						<Link to="/signup" className="btn btn-block btn-success btn-lg">
							{langConfig.commons.signup}
						</Link>
					</div>
					<br />
					<div className={isReadMore ? "special-text expanded" : "special-text"}>
						<h3>Recent Deals:</h3>
						<p className="sectionSubtitle">all prices roundtrip</p>
						Atlanta, GA - Paris, France $291 (American)
						<br />
						San Diego, CA - Vienna, Austria $277 (American)
						<br />
						Philadelphia, PA - San Juan, PR $99 (Frontier)
						<br />
						Birmingham, AL - Iceland $265 (Delta)
						<br />
						Birmingham, AL - Glacier National Park, MT $164 (United)
						<br />
						Orlando, FL - Atlanta, GA $29 (Frontier)
						<br />
						<br />
						And hundreds of more deals everyday!
						<br />
						<br />
						<h3>Book one and be a part of the story.</h3>
						<br />
						<br />
					</div>
					<button
						className="expand-button btn btn-secondary btn-block"
						onClick={handleReadLessOrMore}
						style={{ color: "black" }}
					>
						{isReadMore ? "See Less" : "See recent deals"}
					</button>
				</div>
			</div>
		);
	} else {
		return (
			<div className="lander">
				<h1>{config.common.appName}</h1>
				<p>{config.common.tagline}</p>
				<div>
					<Link to="/login" className="btn btn-info btn-lg">
						{langConfig.commons.login}
					</Link>
					<Link to="/signup" className="btn btn-success btn-lg">
						{langConfig.commons.signup}
					</Link>
				</div>
			</div>
		);
	}
}
