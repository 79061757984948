import React from "react";
import ReactDOM from "react-dom";
import Amplify, { Auth } from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import config from "./config";
import "./index.css";

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		// identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID,
	},
	// Storage: {
	//   region: config.s3.REGION,
	//   bucket: config.s3.BUCKET,
	//   identityPoolId: config.cognito.IDENTITY_POOL_ID
	// },
	API: {
		endpoints: [
			{
				name: "smartDeals",
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION,
				custom_header: async () => {
					// return { Authorization : 'token' }
					// Alternatively, with Cognito User Pools use this:
					return { Authorization: (await Auth.currentSession()).accessToken.jwtToken };
				},
			},
			{
				name: "syncUserAttributes",
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION,
				custom_header: async () => {
					// return { Authorization : 'token' }
					// Alternatively, with Cognito User Pools use this:
					return { 
						Authorization: (await Auth.currentSession()).accessToken.jwtToken,
						 
					};
				},
			},
			{
				name: "getInvoice",
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION,
				custom_header: async () => {
					// return { Authorization : 'token' }
					// Alternatively, with Cognito User Pools use this:
					return { 
						Authorization: (await Auth.currentSession()).accessToken.jwtToken 
					};
				},
			},
			{
				name: "originAirports",
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION,
			},
		],
	},
});

ReactDOM.render(
	<Router>
		<App />
	</Router>,
	document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
