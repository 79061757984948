import React, { useState } from "react";
import { Auth, API } from "aws-amplify";
import { PageHeader, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./FlightSettings.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function FlightSettings(props) {
	const [checkedItems, setCheckedItems] = useState([]);
	const [isFirstRun, setIsFirstRun] = useState(true);
	const [isAirportsRequested, setIsAirportsRequested] = useState(false);
	const [allAirports, setAllAirports] = useState(null);
	const [user, setUser] = useState(null);
	const [isUserRequested, setIsUserRequested] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");

	// const user = useGetUserHook({
	//   isAuthenticated: props.isAuthenticated
	// });
	// const user = props.user;

	async function getUser() {
		if (!isUserRequested) {
			setIsUserRequested(true);
			return setUser(await Auth.currentAuthenticatedUser());
		}
	}

	getUser();

	async function getOriginAirports() {
		if (!isAirportsRequested) {
			setIsAirportsRequested(true);
			let apiName = "originAirports";
			let path = "/originAirports";
			let myInit = {};
			return setAllAirports(await API.get(apiName, path, myInit));
		}
	}

	getOriginAirports();

	// handle submitting settings change
	const handleSubmitSettings = (event) => {
		// update user attributes
		Auth.currentAuthenticatedUser().then(async function (user) {
			// update user attributes
			let result = await Auth.updateUserAttributes(user, {
				"custom:airports": checkedItems.toString(),
			});
			if (result == "SUCCESS") {
				// syncUserAttributes

				let apiName = "syncUserAttributes";
				let path = "/syncUserAttributes"; // '/' + apiName;
				let myInit = {
					body: {
						airports: checkedItems.toString(),
					}, // replace this with attributes you need
					headers: {
						Authorization: user.signInUserSession.idToken.jwtToken,
					}, // OPTIONAL
				};
				await API.post(apiName, path, myInit)
					.then(function (res) {})
					.catch(function (e) {
						console.log("error in call to new api to sync airports: " + JSON.stringify(e));

						// setCountApiRequests(countApiRequests + 1);
						// if (countApiRequests >= maxReqTries) {
						//   setIsApiRequested(true);
						// }
					});

				toast("Settings saved!", {
					position: "top-center",
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					style: "text-align: center",
				});
			} else {
				toast("There was a problem saving your settings", {
					position: "top-center",
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					style: "text-align: center",
				});
			}
		});
	};

	function presentAirportsAsUi(objects, preCheckedAirports, checked_items_temp_state, user1) {
		// set checkedItems to preCheckedAirports if first run
		let tempCheckedItems = [];

		// if it's the first run - set the user's airports from their attributes
		if (isFirstRun) {
			try {
				tempCheckedItems = preCheckedAirports.split(",");
				setCheckedItems(tempCheckedItems);
			} catch (e) {
				console.log("user has no airports");
			}
			setIsFirstRun(false);
		}

		// handle changes to the inputs
		const handleCheckboxChange = (event) => {
			// if the user checked a box
			if (event.target.checked) {
				tempCheckedItems = [...checkedItems, event.target.name];
				setCheckedItems(tempCheckedItems);
			}
			// if the user unchecked a box
			else {
				// filter that array
				tempCheckedItems = checkedItems.filter((item) => item !== event.target.name);
				setCheckedItems(tempCheckedItems);
			}
		};

		// return UI components
		return [{}].concat(objects).map(function (object, i) {
			if (i !== 0) {
				return (
					<ListGroupItem key={i}>
						<input
							type="checkbox"
							onChange={handleCheckboxChange}
							name={object.airportCode}
							checked={checkedItems.indexOf(object.airportCode) > -1}
						/>
						{" " + object.airportCode + " - " + object.airportName}
					</ListGroupItem>
				);
			}
		});
	}

	function presentLoadingUi() {
		let buttonProps = {
			isLoading: true,
			text: "sometext",
			loadingText: "loadingText",
		};
		return (
			<LoaderButton
				block
				bsSize="large"
				disabled="false"
				type="submit"
				isLoading="true"
				text="Login"
				loadingText="Logging in…"
			/>
		);
	}

	const filterAirportsBySearchTerm = (airports, searchTerm) => {
		return airports.filter(
			(data) =>
				data.airportCode.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
				data.airportName.toLowerCase().includes(searchTerm.trim().toLowerCase()),
		);
	};

	// ************************
	// RETURN to UI
	// ************************
	if (props.isAuthenticated) {
		return (
			<div className="FlightSettings">
				<PageHeader style={{ textAlign: "center" }}>
					{props.configLang.pageTitles.settings}
				</PageHeader>
				<br />
				<div>
					<button className="btn btn-lg btn-info btn-block" onClick={handleSubmitSettings}>
						Save airports
					</button>
				</div>
				<br />
				<br />
				<div>
					<h3 style={{ textAlign: "center" }}>
						{props.configLang.settingsPage.selectAirportsLabel}
					</h3>

					{/*           
          For debugging
          <h3>airports: {allAirports && allAirports.length ? "true" : "false"}</h3>
          <h3>user: {user && user.attributes ? JSON.stringify(user.attributes) : "false"}</h3>
           */}

					<p style={{ textAlign: "center" }}>
						{props.configLang.settingsPage.selectAirportsDescription}
					</p>
					<br />
					<div style={{ margin: "auto", width: "100%", textAlign: "center" }}>
						<h5>Selected Airports:</h5>
						<b>{checkedItems.toString()}</b>
					</div>
					<br />
					<br />
					{/* input box for searchTerm - set useState object searchTerm to its value */}
					<label htmlFor="searchTerm">
						<b>Search for an airport</b>
					</label>
					<br />
					<input
						type="text"
						id="searchTerm"
						placeholder="ATL, Atlanta, etc."
						value={searchTerm}
						style={{ width: "100%" }}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<ToastContainer />
					<br />
					<br />
					<ListGroup>
						{(user &&
							user.attributes &&
							allAirports &&
							allAirports.length > 0 &&
							presentAirportsAsUi(
								filterAirportsBySearchTerm(allAirports, searchTerm),
								user.attributes["custom:airports"],
								checkedItems,
								user,
							)) ||
							presentLoadingUi()}
					</ListGroup>
				</div>
				<div>
					<button className="btn btn-lg btn-info btn-block" onClick={handleSubmitSettings}>
						{props.configLang.commons.save}
					</button>
				</div>
				<br />
				<br />
			</div>
		);
	}
	// if user needs login
	else {
		// redirect user to login
		window.location.href = config.links.dashboard;
	}
}
