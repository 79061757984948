import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PageHeader, ListGroup, Button } from "react-bootstrap";
import "./DealsPage.css";
import config from "../config";
import LoaderButton from "./LoaderButton";
import FlightCard from "./FlightCard";
import HomePageLoggedOut from "./HomePageLoggedOut";
import { useGetFlightHook } from "../hooks/custom-hook/useGetFlightHook";
import Carousel from "nuka-carousel";
// import {BasicFunctionalComponent} from '../components/BasicFunctionalComponent';

export default function DealsPage(props, dealType, pageHeader, pageDescription) {
	const flights = useGetFlightHook({
		dealType: dealType,
		isAuthenticated: props.isAuthenticated,
		uuid: props.cognitoId,
	});

	if (props.isAuthenticated) {
		return (
			<div className="Deals">
				{showPageHeader(dealType, pageHeader)}
				{showPageSummaryDetails(props, pageDescription)}
				{showFlightDataCarousel(dealType, flights, props)}
			</div>
		);
	}

	// if user needs login
	else {
		return (
			<div className="Deals">
				{
					// TODO change this to redirect to the home (login) page
					HomePageLoggedOut(props, props.configLang, config, false)
				}
			</div>
		);
	}
}

// User needs to setup airports
function presentSetupAirportsUi(_props) {
	let langConfig = _props.configLang;
	return (
		<div className="lander" style={{ textAlign: "center" }}>
			<p>{/*langConfig.commons.airportSetupRequired*/}</p>
			<div>
				<Link to={config.links.settings} className="btn btn-info btn-lg">
					{langConfig.pageTitles.settings}
				</Link>
			</div>
		</div>
	);
}

// display flights that were received from request
function presentFlightsAsUi(objects, dealType, cognitoId) {
	return [{}].concat(objects).map(function (flight, i) {
		if (i !== 0) {
			flight.i = i;

			// Return the UI component from a function component
			return FlightCard(flight, dealType, cognitoId);
		}
	});
}

function presentSummaryFlightsAsUi(objects, dealType, cognitoId) {
	return [{}].concat(objects).map(function (flightTable, i) {
		if (i >= 2 && i < objects.length - 1) {
			flightTable.i = i;

			// Return the UI component from a function component
			if (flightTable.flightGroup[0] != null && flightTable.flightGroup[0].startdate != null) {
				return FlightCard(flightTable.flightGroup[0], dealType, cognitoId);
			} else {
				return ""
			}
		}
	});
}

function showSummaryFlights(objects, dealType, _props) {
	let langConfig = _props.configLang;
	let cognitoId = _props.cognitoId;

	return (
		<div className="carouselContentDiv">
			<h2 className="slideHeader">{langConfig.pageTitles["region"]}</h2>
			<p className="sectionSubtitle">Full Summary</p>
			<ListGroup>{presentSummaryFlightsAsUi(objects, dealType, cognitoId)}</ListGroup>
		</div>
	);
}

function presentCarousel(objects, dealType, _props) {
	let langConfig = _props.configLang;
	let cognitoId = _props.cognitoId;

	// TODO: create a summary slide from the first item in each table
	return [{}].concat(objects).map(function (flightTable, i) {
		if (i > 1 && flightTable.flightGroup.length > 0) {
			flightTable.i = i;

			return (
				<div className="carouselContentDiv">
					<h2 className="slideHeader">{langConfig.pageTitles[flightTable.titleCode]}</h2>
					<p className="sectionSubtitle">
						{flightTable.titleCode == "region" ? flightTable.title : ""}
					</p>
					<ListGroup>{presentFlightsAsUi(flightTable.flightGroup, dealType, cognitoId)}</ListGroup>
				</div>
			);
		}
	});
}

function presentLoadingUi(_props) {
	let langConfig = _props.configLang;
	let buttonProps = {
		isLoading: true,
		text: "sometext",
		loadingText: "loadingText",
	};
	return (
		<LoaderButton
			block
			bsSize="large"
			disabled="false"
			type="submit"
			isLoading="true"
			text="Loading"
			loadingText={langConfig.commons.loadingFlights}
		/>
	);
}

function showDescription(text, index) {
	if (index != 0) {
		return (
			<div>
				<br />
				<br />
				{text}
			</div>
		);
	} else {
		return text;
	}
}

function presentUnauthorizedMessage() {
	return <p>This page requires a premium membership.</p>;
}

// Show the summary and details of each page in expandable format
const showPageSummaryDetails = (props, pageDescription) => {
	const [isReadMore, setIsReadMore] = useState(false);

	const handleReadLessOrMore = (event) => {
		setIsReadMore(!isReadMore);
	};

	return (
		<div>
			<div>
				<p className="page-description">
					{pageDescription &&
						pageDescription[0] &&
						pageDescription[0].text &&
						showDescription(pageDescription[0].text, 0)}
					{pageDescription &&
						pageDescription[1] &&
						pageDescription[1].text &&
						showDescription(pageDescription[1].text, 1)}
				</p>
				<br />
			</div>
		</div>
	);
};

const showPageHeader = (dealType, pageHeader) => {
	return dealType != "region" ? <PageHeader>{pageHeader}</PageHeader> : "";
};

const getFlightCount = (flights) => {
	if (flights && flights.flights && flights.flights.flightMultiTables && flights.flights.flightMultiTables.length > 0) {
		// flights.flights.flightMultiTables
		// in each flightMultiTables, look at the flightGroup element and count its length
		let totalFlights = 0;
		flights.flights.flightMultiTables.forEach(table => {
			totalFlights += table.flightGroup.length;
		});
		return totalFlights;
	}
	else {
		return 0;
	}
}

const showFlightDataCarousel = (dealType, flights, props) => {
	// var to determine if we can show the tables
	var flightDataReady =
		flights &&
		flights.isApiRequestSuccessful &&
		flights.flights &&
		flights.flights.flightMultiTables &&
		getFlightCount(flights) > 0;

	return dealType != "region" ? (
		<ListGroup>
			{
				// if flights done loading
				(flights &&
					flights.isApiRequestSuccessful &&
					presentFlightsAsUi(flights.flights.flightGroup, dealType, props.cognitoId)) ||
					(flights && flights.isUserMissingAirports && presentSetupAirportsUi(props)) ||
					(!flights.isUserAuthorized && presentUnauthorizedMessage()) ||
					presentLoadingUi(props)
			}
		</ListGroup>
	) : (
		<Carousel
			heightMode={"current"}
			wrapAround={true}
			renderTopCenterControls={({ currentSlide, slideCount }) => (
				<div className="slideCountDiv">
					{flights && flights.isApiRequestSuccessful ? currentSlide + 1 + " of " + slideCount : ""}
				</div>
			)}
			renderTopLeftControls={({ previousSlide }) => (
				<button className="btn btn-lg btn-warning carouselnavbutton" onClick={previousSlide}>
					&lt;&lt;
				</button>
			)}
			renderCenterLeftControls={() => <div></div>}
			renderCenterRightControls={() => <div></div>}
			renderBottomCenterControls={() => <div></div>}
			renderTopRightControls={({ nextSlide }) => (
				<button className="btn btn-lg btn-success carouselnavbutton" onClick={nextSlide}>
					&gt;&gt;
				</button>
			)}
		>
			{
				// if flights done loading - show summary flight table
				flightDataReady && showSummaryFlights(flights.flights.flightMultiTables, dealType, props)
			}
			{
				// if flights done loading - show main flight tables
				(flightDataReady && presentCarousel(flights.flights.flightMultiTables, dealType, props)) ||
					(flights && flights.isUserMissingAirports && presentSetupAirportsUi(props)) ||
					(!flights.isUserAuthorized && presentUnauthorizedMessage()) ||
					presentLoadingUi(props)
			}
		</Carousel>
	);
};
