import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import "./Memberships.css";
import config from "../config.js";

export default function Memberships(props) {
	const [membershipLevel, setMembershipLevel] = useState(0);
	const [invoiceId, setInvoiceId] = useState(0);
	const [membershipCost, setMembershipCost] = useState(1);
	const [maxShownMembershipLevel, setMaxShownMembershipLevel] = useState(2);
	const [userCurrentMembership, setUserCurrentMembership] = useState(0)

	const memberships = config.memberships;

	// compute tax and grand total

	const invoiceUser = (user, sessionVariable) => {
		API.post("getInvoice", "/getInvoice", {
			body: {
				membershipLevel: membershipLevel,
				uuid: sessionVariable.idToken.payload.sub
			},
			headers: {
				Authorization: user.signInUserSession.idToken.jwtToken,
			}, // OPTIONAL
		}).then((result) => {
			setInvoiceId(result.invoiceId);
			setMembershipCost(result.getMembershipCost);
		}).catch((e) => {
			console.log(`ERROR: ${JSON.stringify(e)}`);
		});
	}

	// when new membership level selected
	useEffect(() => {
		try {
			// let result = await invoiceUser();
			// Get user auth and get deals
			Auth.currentSession()
			.then(function (sessionVariable) {
				Auth.currentAuthenticatedUser()
				.then(async (user) => {
					if (membershipLevel > 0)
						invoiceUser(user, sessionVariable)
				})
				.catch((e) => {
					console.log("Problem obtaining user authentication");
				});
			})
			.catch((e) => {
				console.log("Problem obtaining current session");
			})
			// props.history.push("/");
		} catch (e) {
			alert(e);
		}
	}, [membershipLevel]);

	const renderMemberships = () => {
		const pStyle = {
			lineHeight: "0px",
			fontSize: "13px",
			marginHeight: 0,
		};
		const divStyle = {
		};
		const headerStyle = {
			lineHeight: 0.5
		};
		return memberships.map((item, index) => {
			if (item.id == maxShownMembershipLevel || item.id == maxShownMembershipLevel - 1)
			return (
				<div key={item.id}>
					<h3 style={headerStyle}>{item.title} - ${item.price}</h3>
					<div style={divStyle}>
						{item.id > 1 ? <><hr /><div style={pStyle}>{"Everything above"}</div></> : ""}
						{item.description.map(desc => <><hr /><div style={pStyle}>{desc}</div></>)}
					</div>
					<br />
					<button onClick={() => setMembershipLevel(item.id)} className="btn btn-primary">Buy Membership</button>
					<br /><br />
				</div>
			)
		})
	}

	const renderRequestedMembership = (id) => {
		const divStyle = {
			lineHeight: 0,
		};
		const pStyle = {
		}
		const headerStyle = {
			lineHeight: 0.5,
		};
		id = id-1
		return (
			<div>
				<h3 style={headerStyle}>{memberships[id].title} Membership - ${memberships[id].price}</h3>
				<div style={divStyle}>
					{memberships[id].description.map(desc => <p style={pStyle}><hr />{desc}</p>)}
					<hr />
				</div>
				<br />
			</div>
		)
	}

	const changeMaxShownMembershipLevel = (isIncreasing) => {
		if (isIncreasing) {
			if (maxShownMembershipLevel + 2 <= memberships.length + 1) {
				setMaxShownMembershipLevel(maxShownMembershipLevel + 2)
			}
		}
		else {
			if (maxShownMembershipLevel - 2 >= 2) {
				setMaxShownMembershipLevel(maxShownMembershipLevel - 2)
			}
		}
	}

	const isMembershipSelectedForCheckout = () => {
		return membershipLevel > 0 && membershipCost > 1 && invoiceId != 0;
	}

	const parseMembershipGroups = (props) => {
		if (props.user.signInUserSession.idToken.payload["cognito:groups"]) {
			let userLevel = ""
			props.user.signInUserSession.idToken.payload["cognito:groups"].forEach(group => {
				if (group.includes("level")) {
					userLevel = group.split("level")[1]
				}
			})
			return memberships[userLevel-1].title;
		}
		else {
			return "Basic"
		}
	}

	const showMembershipOptions = (props) => {
		const headerStyle = {
			lineHeight: 1
		}
		const levelStyle = {
			lineHeight: 1
		}
		return (
			<div>
				<h2 style={headerStyle}>Memberships</h2>
				{
					userCurrentMembership > 0 ?
					<div className="btn-group btn-block" role="group">
						<div>
							<button className="btn btn-sm btn-dark">Member Status<br />Status</button>
							<button className="btn btn-sm btn-secondary">&nbsp;Expiration Date&nbsp;<br />Date</button>
						</div>
					</div>
					:
					<h3 style={levelStyle}>My Level: {parseMembershipGroups(props)}</h3>
				}

				<div className="btn-group btn-block" role="group">
					<button className="btn btn-secondary" onClick={() => changeMaxShownMembershipLevel(false)}>{"<<"}</button>
					<button className="btn btn-dark" onClick={() => changeMaxShownMembershipLevel(true)}>{">>"}</button>
				</div>
				
				<br /><br />
				{
					renderMemberships()
				}
			</div>
		)
	}

	const showCheckout = () => {
		return (
			<div>
				<h1>Checkout</h1>
				<button onClick={() => setMembershipLevel(0)} className="btn btn-secondary btn-lg btn-block">Edit Selection</button>
				<br />
				{renderRequestedMembership(membershipLevel)}
				<p>In order to improve quality of the community, <b><u>all payments must be made in Bitcoin</u></b></p>
				<br />
				<form method="POST" action="https://btcpay.flylert.com/api/v1/invoices" target="_blank" className="btcpay-form btcpay-form--block">
					<input type="hidden" name="storeId" value="B1drZsJw9ZPEi8evGGuGQTNVnHkdXfifbFMqEwVjGYiy" />
					<input type="hidden" name="orderId" value={invoiceId} />
					<input type="hidden" name="checkoutDesc" value="membership" />
					<input type="hidden" name="price" value={membershipCost} />
					<input type="hidden" name="currency" value="USD" />
					<input type="image" className="submit" name="submit" src="https://btcpay.flylert.com/img/paybutton/pay.svg" style={{width: 209}} alt="Pay with BtcPay, Self-Hosted Bitcoin Payment Processor" />
				</form>
				<p style={{fontSize: 10}} className="finePrint">All prices are for 30 days access only. Membership will not auto renew. It must be paid every 30 days. Payment will occur on a different page.</p>
				<a target="_blank" href="https://help.coinbase.com/en/coinbase/trading-and-funding/buying-selling-or-converting-crypto/how-do-i-buy-digital-currency" className="btn btn-primary btn-lg btn-block">how to buy bitcoin</a>
				<a target="_blank" href="https://help.coinbase.com/en/coinbase/trading-and-funding/cryptocurrency-trading-pairs/how-to-send-and-receive-cryptocurrency" className="btn btn-primary btn-lg btn-block">how to send bitcoin</a>
				<br />
				<p>After completing payment, you can go to any page. Your dashboard will update shortly.</p>
			</div>
		)
	}

	return (
		props && props.user && props.user.signInUserSession ?
		<div className="Settings text-center">
			<br />
			{
				isMembershipSelectedForCheckout() 
					?
					showCheckout()
					: 
					showMembershipOptions(props)
			}
		</div>
		:
		<div className="Settings text-center">
			<h1>Loading...</h1>
		</div>
	);
}
