import React from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import MaterialIcon, { colorPalette } from "material-icons-react";
import { ListGroupItem } from "react-bootstrap";
import config from "../config.js";

export default function FlightCard(flight, flightObjectType, cognitoId) {
	// general url and standardize dates
	let flightObj = generateUrl(flight);
	flight.url = flightObj.url;
	flight.startdate = flightObj.datestart;
	flight.enddate = flightObj.dateend;

	function generateUrl(flight) {
		if (flightObjectType != "clicks") {
			let datestart = flight.startdate.split("T")[0];
			let dateend = flight.enddate.split("T")[0];
			let url =
				config.links.redirect +
				"?" +
				"&userUniqueID=" +
				cognitoId +
				"&price=" +
				flight.todaylowprice +
				"&origin=" +
				flight.origin +
				"&destination=" +
				flight.destination.normalize("NFD").replace(/[\u0300-\u036f]/g, "") +
				"&startdate=" +
				datestart +
				"&enddate=" +
				dateend +
				"&isCustomTrip=" +
				"" +
				"&isNonstop=" +
				flight.isNonstop +
				"&isGreatDeal=" +
				0 + // flight.isGreatDeal
				"&isSummarySection=" +
				0 +
				"&isPriceImprovementSection=" +
				0 +
				"&src=2" + // email is 1 , site is 2
				"&regionID=" +
				flight.regionDestination;
			return { url: url, datestart: datestart, dateend: dateend };
		} else {
			let datestart = flight.prices_startdate.split("T")[0];
			let dateend = flight.prices_enddate.split("T")[0];
			let url =
				config.links.redirect +
				"?" +
				"&userUniqueID=" +
				cognitoId +
				"&price=" +
				flight.prices_price +
				"&origin=" +
				flight.prices_origin +
				"&destination=" +
				flight.prices_destination.normalize("NFD").replace(/[\u0300-\u036f]/g, "") +
				"&startdate=" +
				datestart +
				"&enddate=" +
				dateend +
				"&isCustomTrip=" +
				"" +
				"&isNonstop=" +
				flight.prices_isNonstop +
				"&isGreatDeal=" +
				0 + // flight.isGreatDeal
				"&isSummarySection=" +
				0 +
				"&isPriceImprovementSection=" +
				1 +
				"&src=2" + // email is 1 , site is 2
				"&regionID=" +
				flight.log_regionID;
			return { url: url, datestart: datestart, dateend: dateend };
		}
	}

	// render based on whether the request is in a special format for recent clicks which will contain more data
	if (flightObjectType == "carousel") {
		return (
			<span
				key={flight.i} // provide the unique key id
				className="list-group-item"
			>
				<a className="btn btn-xs" href={`${flight.url}`} target="_blank" rel="noopener noreferrer">
					<span>
						<h4 className="pull-left">
							{"$" + flight.todaylowprice + ": " + flight.origin + " - " + flight.destination}
						</h4>
						<br />
						<span className="pull-left">
							{flight.startdate.split("T")[0].substring(5, 10) +
								" - " +
								flight.enddate.split("T")[0].substring(5, 10) +
								(flight.destinationName ? ": " + flight.destinationName : "") +
								(flight.isNonstop == "1" ? " -- NONSTOP" : "")}
						</span>
						<span className="pull-left">
							{flight.orig_regi_priceRank ? "- region rank: " + flight.orig_regi_priceRank : ""}
						</span>
					</span>
				</a>
				<span className="align-middle pull-right btn btn-xs">
					<br />
					<MaterialIcon
						icon={0 == 1 ? null /*"favorite_border"*/ : null /*"favorite"*/}
						size={15}
					/>
				</span>
			</span>
		);
	} else if (flightObjectType != "clicks") {
		return (
			<span
				key={flight.i} // provide the unique key id
				className="list-group-item"
			>
				<a
					className="btn btn-xs flight-card-link"
					href={`${flight.url}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<span>
						<h4 className="pull-left">
							{"$" + flight.todaylowprice + ": " + flight.origin + " - " + flight.destination}
						</h4>
						<br />
						<span className="pull-left">
							{flight.startdate.split("T")[0].substring(5, 10) +
								" - " +
								flight.enddate.split("T")[0].substring(5, 10) +
								(flight.destinationName ? ": " + flight.destinationName : "") +
								(flight.isNonstop == "1" ? " -- NONSTOP" : "")}
						</span>
						<span className="pull-left">
							{flight.orig_regi_priceRank ? "- region rank: " + flight.orig_regi_priceRank : ""}
						</span>
					</span>
				</a>
				<span className="align-middle pull-right btn btn-xs">
					<br />
					<MaterialIcon
						icon={0 == 1 ? null /*"favorite_border"*/ : null /*"favorite"*/}
						size={15}
					/>
				</span>
			</span>
		);
	} else {
		return (
			<span
				key={flight.i} // provide the unique key id
				className="list-group-item"
			>
				<a className="btn btn-xs" href={`${flight.url}`} target="_blank" rel="noopener noreferrer">
					<span className="pull-left">
						<h4>
							{"$" +
								flight.prices_price +
								": " +
								flight.prices_origin +
								" - " +
								flight.prices_destination}
						</h4>
						<p className="pull-left">
							{flight.startdate.split("T")[0].substring(5, 10) +
								" - " +
								flight.enddate.split("T")[0].substring(5, 10)}
						</p>
					</span>
				</a>
				<span className="align-middle pull-right btn btn-xs">
					<br />
					<MaterialIcon
						icon={0 == 1 ? null /*"favorite_border"*/ : null /*"favorite"*/}
						size={15}
					/>
				</span>
			</span>
		);
	}
}
