import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";

export default function Login(props) {
	const [isLoading, setIsLoading] = useState(props && props.isLoading ? props.isLoading : false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [isAuthenticated, setIsAuthenticated] = useState(
		props && props.isAuthenticated ? props.isAuthenticated : false,
	);

	function validateForm() {
		return email.length > 0 && password.length > 0;
	}

	async function handleSubmit(event) {
		event.preventDefault();

		setIsLoading(true);

		try {
			await Auth.signIn(email, password);
			setIsAuthenticated(true);
			window.location.href = "/";
		} catch (e) {
			alert("made it past this.props.user..." + e.message);
			setIsLoading(false);
		}
	}

	return (
		<div className="Login">
			<form onSubmit={handleSubmit}>
				<FormGroup controlId="email" bsSize="large">
					<FormControl
						autoFocus
						type="email"
						value={email}
						onChange={(event) => setEmail(event.target.value)}
						placeholder="email"
					/>
				</FormGroup>
				<FormGroup controlId="password" bsSize="large">
					<FormControl
						value={password}
						onChange={(event) => setPassword(event.target.value)}
						type="password"
						placeholder="password"
					/>
				</FormGroup>
				<br />
				<Link to="/login/reset">Forgot password?</Link>
				<br />
				<LoaderButton
					block
					bsSize="large"
					disabled={!validateForm()}
					type="submit"
					isLoading={isLoading}
					className="btn btn-info"
					text="Login"
					loadingText="Logging in…"
				/>
			</form>
		</div>
	);
}
