// import * as Sentry from "@sentry/browser";

const isLocal = process && process.env && process.env.NODE_ENV === "development";

export function initSentry() {
	if (isLocal) {
	}

	// TODO: create Sentry account
	// Sentry.init({ dsn: "https://5f83aa2e21064e47bab8a1f308f940eb@sentry.io/5185720" });
}

export function logError(error, errorInfo = null) {
	if (isLocal) {
	}

	//   Sentry.withScope((scope) => {
	//     errorInfo && scope.setExtras(errorInfo);
	//     Sentry.captureException(error);
	//   });
}

export function onError(error) {
	let errorInfo = {};
	let message = error.toString();

	// Auth errors
	if (!(error instanceof Error) && error.message) {
		errorInfo = error;
		message = error.message;
		error = new Error(message);
		// API errors
	} else if (error.config && error.config.url) {
		errorInfo.url = error.config.url;
	}

	logError(error, errorInfo);

	alert(message);
}
