import DealsPage from "../components/DealsPage";

//
export default function ClickDeals(props) {
	// page customization variables
	const dealType = "cheap";
	let pageHeader;
	let pageDescription;
	if (props.configLang) {
		pageHeader = props.configLang.pageTitles.clickDeals;
		pageDescription = props.configLang.descriptions.clickDeals;
	} else {
		pageHeader = "Cheapest Deals";
		pageDescription = [
			{ text: "These are the flights that are the absolute cheapest from your selected airports." },
		];
	}

	// RETURN the UI from common DealsPage
	return DealsPage(props, dealType, pageHeader, pageDescription);
}
