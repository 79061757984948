import React, { useContext } from "react";

const english = {
	appHeaders: {
		appName: "Flylert",
		tagline: "low airfare for flexible adventures",
		tabTitle: "Flylert | Disrupting Travel Industry",
	},
	pageTitles: {
		smartFlights: "Flights - Smart",
		allSmartFlights: "Flights - Smart (All Airports)",
		cheapFlights: "Flights - Cheap",
		clickDeals: "Flights - Clicks",
		regionDeals: "Flights - Region",
		settings: "Change Home Airports",
		payment: "Payment Info",
		bugs: "Known Bug List",
		smart: "Smart Deals",
		region: "Region Deals",
		cheap: "Cheap Deals",
		dashboardDeals: "",
	},
	descriptions: {
		smartFlights: [
			{
				text:
					"These are the flight deals that we think are the best based on historical data for YOUR AIRPORT ORIGINS....",
			},
			{
				text:
					'"Region Rank" indicates that trip\'s price rank from that origin to other destinations in that region - 1 being the best.',
			},
		],
		allSmartFlights: [
			{
				text:
					"These are the flight deals that we think are the best based on historical data for ALL AIRPORT ORIGINS....",
			},
			{
				text:
					'"Region Rank" indicates that trip\'s price rank from that origin to other destinations in that region - 1 being the best.',
			},
		],
		cheapFlights: [
			{
				text:
					"These are the absolute lowest fares ranked from lowest to highest. Usually the lowest fares will be to some of the closest major destinations.",
			},
		],
		clickDeals: [{ text: "These are the flights that you've recently clicked on." }],
		regionDeals: [{ text: "These are the best flight deals by region." }],
		settings: [{ text: "" }],
		payment: [{ text: "" }],
		bugs: [{ text: "" }],
		dashboardDeals: [{ text: "All our best deals are in the rotating screen below" }],
	},
	commons: {
		login: "Login",
		signup: "Signup",
		logout: "Logout",
		blog: "Blog",
		whatsThis: "What's this page?",
		email: "email",
		password: "password",
		confirmPassword: "Confirm Password",
		loadingFlights: "Loading Flights...",
		loadingPage: "Loading Page...",
		airportSetupRequired: "Go to settings page to setup your airports",
		arabic: "Arabic الترجمة العربية",
		prevPage: "Back",
		nextPage: "Next",
		save: "save",
	},
	signupPage: {
		emailLabel: "Email",
		passwordLabel: "Password",
		confirmPasswordLabel: "Confirm Password",
		termsAndConditionsCheckboxStatement:
			"By checking this box, I agree to and have fully read and understand the ",
		termsAndConditionsCheckboxLabel: "terms and conditions and privacy policy.",
		dailyEmailCheckboxLabel:
			"By checking this box, I agree to receive daily emails with flight deals.",
		referredByLabel: "Who referred you?",
		preferredTripsLabel: "Tell us where you want to go.",
		preferredAirportsLabel: "Can't find airport? Tell us.",
		selectedAirportsLabel: "Choose aiports: ",
		confirmButtonLabel: "Confirm & Submit",
		confirmationCodeLabel: "Confirmation Code",
		confirmationCodeHelpTextLabel: "Please check your email (and your SPAM folder!) for the code.",
		confirmInfoLabel: "Confirm your info",
		selectedAirportsLabel: "Select your HOME airports: ",
		selectedAirportsHelpLabel: "(not destinations)",
		missingAirportsLabel: "Airports you'd like us to add: ",
		requestedTripsLabel: "Requested Trips: ",
		referredByLabel: "Referred By: ",
		passwordValidationLabel: "password must be 8 characters",
		airportValidationLabel: "You must select HOME airports or type them in the box",
		optionalLabel: "Optional",
	},
	dealsPages: {},
	paymentPage: {
		cardholderNameLabel: "Cardholder's name",
		nameOnCardTextHelp: "Name on the card",
		creditCardInfoLabel: "Credit Card Info",
		cardNumberTextHelp: "Card number",
		monthYearText: "MM / YY",
		purchaseBtn: "Purchase",
	},
	settingsPage: {
		selectAirportsLabel: "Select home airports",
		selectAirportsDescription: "Basic users can select up to 5 airports.",
		selectedAirportsLabel: "Selected Airports: ",
		emailFrequencyLabel: "Receive emails every how many days:",
		emailActive: "Want to receive emails?",
	},
};

const arabic = {
	appHeaders: {
		appName: "يطير في حالة تأهب",
		tagline: "أقل تكلفة للسفر جوا",
		tabTitle: "يطير في حالة تأهب",
	},
	pageTitles: {
		smartFlights: "أذكى الصفقات",
		allSmartFlights: "جميع أذكى الصفقات",
		cheapFlights: "أرخص الصفقات",
		clickDeals: "النقرات الحديثة",
		regionDeals: "عروض المنطقة",
		settings: "إعدادت الحساب",
		payment: "معلومات الدفع",
		bugs: "مشكلة البرمجيات",
	},
	descriptions: {
		smartFlights: [
			{
				text:
					"هذه هي عروض الرحلات الجوية التي نعتقد أنها الأفضل بناءً على البيانات التاريخية لأصول مطارك",
			},
			{
				text:
					"يشير تصنيف المنطقة إلى تصنيف سعر الرحلة من ذلك الأصل إلى وجهات أخرى في تلك المنطقة - حيث أن أدنىها هو الأفضل.",
			},
		],
		allSmartFlights: [
			{
				text:
					"هذه هي عروض الرحلات الجوية التي نعتقد أنها الأفضل بناءً على البيانات التاريخية لجميع المطارات الأصلية",
			},
			{
				text:
					"يشير تصنيف المنطقة إلى تصنيف سعر الرحلة من ذلك الأصل إلى وجهات أخرى في تلك المنطقة - حيث أن أدنىها هو الأفضل.",
			},
		],
		cheapFlights: [
			{
				text:
					"هذه هي أدنى الأسعار المطلقة المرتبة من الأدنى إلى الأعلى. عادة ما تكون أقل الأسعار إلى بعض من أقرب الوجهات الرئيسية",
			},
		],
		clickDeals: [{ text: "هذه هي الرحلات التي نقرت عليها مؤخرًا" }],
		regionDeals: [{ text: "هذه هي أفضل عروض الرحلات حسب المنطقة" }],
		settings: [{ text: "" }],
		payment: [{ text: "" }],
		bugs: [{ text: "" }],
	},
	commons: {
		login: "تسجيل الدخول",
		signup: "سجل",
		logout: "الخروج",
		blog: "Blogسجل",
		whatsThis: "ما هذه الصفحة",
		email: "البريد الإلكتروني",
		password: "كلمه السر",
		confirmPassword: "تأكيد كلمة المرور",
		loadingFlights: "تحميل الرحلات الجوية",
		loadingPage: "تحميل الصفحة",
		airportSetupRequired: "انتقل إلى صفحة الإعدادات لاختيار المطارات المحلية",
		arabic: "Arabic الترجمة العربية",
		prevPage: "سجل",
		nextPage: "سجل",
		save: "سجل",
	},
	// TODO: none of the signup page is right
	signupPage: {
		emailLabel: "سجل",
		passwordLabel: "سجل",
		confirmPasswordLabel: "سجل",
		termsAndConditionsCheckboxStatement: "سجل",
		termsAndConditionsCheckboxLabel: "سجل",
		dailyEmailCheckboxLabel: "سجل",
		referredByLabel: "سجل",
		preferredTripsLabel: "سجل",
		preferredAirportsLabel: "سجل",
		selectedAirportsLabel: "سجل",
		confirmButtonLabel: "سجل",
		confirmationCodeLabel: "سجل",
		confirmationCodeHelpTextLabel: "سجل",
		confirmInfoLabel: "سجل ",
		selectedAirportsLabel: "سجل ",
		missingAirportsLabel: "سجل ",
		requestedTripsLabel: "سجل ",
		referredByLabel: "سجل ",
		passwordValidationLabel: "سجل",
		airportValidationLabel: "سجل",
		optionalLabel: "سجل",
	},
	dealsPages: {},
	paymentPage: {
		cardholderNameLabel: "إسم صاحب البطاقة",
		nameOnCardTextHelp: "الاسم على البطاقة",
		creditCardInfoLabel: "معلومات بطاقة الائتمان",
		cardNumberTextHelp: "رقم البطاقة",
		monthYearText: "MM / YY",
		purchaseBtn: "شراء",
	},
	settingsPage: {
		selectAirportsLabel: "اختر المطارات",
		selectAirportsDescription: "يمكن للمستخدمين الأساسيين اختيار ما يصل إلى خمسة مطارات",
		selectedAirportsLabel: "المطارات المختارة ",
		emailFrequencyLabel: "سجل",
		emailActive: "سجل",
	},
};

// var languageConfig = arabic;
// Default to dev if not set
// const config = process.env.REACT_APP_STAGE === 'prod'
//   ? prod
//   : dev;

export default function languageConfig(requestLang) {
	if (requestLang == "english") {
		return {
			MAX_ATTACHMENT_SIZE: 5000000,
			...english,
		};
	}

	const language = requestLang;

	// Add common config values here
	if (language != "eng") {
		return {
			MAX_ATTACHMENT_SIZE: 5000000,
			...arabic,
		};
	}
	return {
		MAX_ATTACHMENT_SIZE: 5000000,
		...english,
	};
}
