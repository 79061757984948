import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import UnsubscribeSuccessful from "./components/UnsubscribeSuccessful";
import Notes from "./containers/Notes";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import ResetPassword from "./containers/ResetPassword";
// import Terms from "./containers/Terms";
import NewNote from "./containers/NewNote";
import Settings from "./containers/Settings";
import Memberships from "./containers/Memberships";
import FlightSettings from "./containers/FlightSettings";
import SmartestDeals from "./containers/SmartestDeals";
import CheapestDeals from "./containers/CheapestDeals";
import AllSmartestDeals from "./containers/AllSmartestDeals";
import ClickDeals from "./containers/ClickDeals";
import Bugs from "./containers/Bugs";
import NotFound from "./containers/NotFound";
import Blog from "./containers/blog/Blog";
import NewBlog from "./containers/blog/NewBlog";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import config from "./config";

export default ({ childProps }) => (
	<Switch>
		<AppliedRoute path="/" exact component={Home} props={childProps} />
		<AppliedRoute path="/dashboard" exact component={Home} props={childProps} />
		<UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
		<UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps} />
		<UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
		<UnauthenticatedRoute
			path="/unsubscribe-successful"
			exact
			component={UnsubscribeSuccessful}
			props={childProps}
		/>
		{/* <UnauthenticatedRoute path="/blog" exact component={Blog} props={childProps} />
    <UnauthenticatedRoute path="/blog/new" exact component={NewBlog} props={childProps} />
    <UnauthenticatedRoute path="/blog/:id" exact component={Blog} props={childProps} /> */}
		<AuthenticatedRoute path="/airports" exact component={FlightSettings} props={childProps} />
		<AuthenticatedRoute path="/settings" exact component={Home} props={childProps} />
		<AuthenticatedRoute path="/memberships" exact component={Memberships} props={childProps} />
		<AuthenticatedRoute path="/notes/new" exact component={NewNote} props={childProps} />
		<AuthenticatedRoute path="/notes/:id" exact component={Notes} props={childProps} />
		<AuthenticatedRoute path="/smartestDeals" exact component={SmartestDeals} props={childProps} />
		<AuthenticatedRoute path="/deals" exact component={Home} props={childProps} />
		<AuthenticatedRoute
			path="/allSmartestDeals"
			exact
			component={AllSmartestDeals}
			props={childProps}
		/>
		<AuthenticatedRoute path="/clickDeals" exact component={ClickDeals} props={childProps} />
		<AuthenticatedRoute path="/cheapestDeals" exact component={CheapestDeals} props={childProps} />
		<AuthenticatedRoute path="/bugs" exact component={Bugs} props={childProps} />
		<Route
			path="/blog"
			component={(props) => {
				// open the redirect window
				window.location.replace("http://flylert.medium.com");
			}}
		/>
		<Route
			path="/flight-click/:flightUrl"
			component={(props) => {
				// create url to redirect to
				var url = config.links.redirect + props.location.search;
				// open the redirect window
				window.location.replace(url);

				// window.location.href = "/"; // localhost:3000";

				// close the /flight-click window
				// window.close();

				// open the redirect window
				// window.open(url, '_blank');
				// return null;
			}}
		/>
		{/* Finally, catch all unmatched routes */}
		<Route component={NotFound} />
	</Switch>
);
