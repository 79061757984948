// do not import useState here
import { API, Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
// import { useEndpoint } from './useEndpoint';

// I would think that this just needs to handle the data that comes back from useEndpoint and send it on back to Home.js - or should this function contain the logic in useEndpoint?
export function useGetApiDataHook(props) {
	// props:
	//    isAuthenticated
	//    isAuthenticationRequired
	//    dealType
	//    uuid
	//    requestMethod
	//    requestEndpoint
	//    requestBody
	//    requestHeaders

	// the useState way
	const [data, setData] = useState(null);
	const [isApiRequested, setIsApiRequested] = useState(false);
	const [isApiRequestSuccessful, setIsApiRequestSuccessful] = useState(false);
	const [countApiRequests, setCountApiRequests] = useState(0);

	useEffect(() => {
		function handleDataChange(res) {
			setData(res);
		}

		// Get user auth and get deals
		if (props.isAuthenticationRequired) {
			// check authentication
			if (!props.isAuthenticated) {
				return;
			}

			// Set deal request params
			const apiName = props.requestEndpoint;
			const path = `/${props.requestEndpoint}`;
			const myInit = {
				body: props.requestBody,
				headers: props.requestHeader,
			};

			// if api not requested too many times, make request
			const maxReqTries = 3;
			if (!isApiRequested && countApiRequests < maxReqTries) {
				if (props.requestMethod == "POST") {
					API.post(apiName, path, myInit)
						.then((res) => {
							setIsApiRequested(true);
							handleDataChange(res);
							setIsApiRequestSuccessful(true);
						})
						.catch((e) => {
							setCountApiRequests(countApiRequests + 1);
							if (countApiRequests >= maxReqTries) {
								setIsApiRequested(true);
							}
						});
				}
			}
		} else {
			if (props.requestMethod == "POST") {
				// TODO
			}
			if (props.requestMethod == "GET") {
				const maxReqTries = 3;
				if (!isApiRequested && countApiRequests < maxReqTries) {
					const apiName = props.requestEndpoint;
					const path = `/${props.requestEndpoint}`;
					API.get(apiName, path)
						.then((res) => {
							setIsApiRequested(true);
							handleDataChange(res);
							setIsApiRequestSuccessful(true);
						})
						.catch((e) => {
							setCountApiRequests(countApiRequests + 1);
							if (countApiRequests >= maxReqTries) {
								setIsApiRequested(true);
							}
						});
				}
			}
		}
	});

	return {
		isApiRequested,
		isApiRequestSuccessful,
		data,
	};
}
