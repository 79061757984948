import React, { useState, useEffect } from "react";
import "./HomePageLoggedOut.css";

export default function UnsubscribeSuccessful (props, langConfig, config, loading) {

  if (langConfig) {
    return (
      <div className="lander">
        <br /><br /><br />
        <h3>Unsubscribed</h3>
        <p>You have successfully unsubscribed. Sorry to see you go. You can still access your account by logging in. Let us know what we can do better by sending an email to support@flylert.com</p>
      </div>
    );
  }
  else {
    return (
      <div className="lander">
        <br /><br /><br />
        <h3>Unsubscribed</h3>
        <p>You have successfully unsubscribed. Sorry to see you go. You can still access your account by logging in. Let us know what we can do better by sending an email to support@flylert.com</p>
      </div>
    );
  }
}
