import React, { useState, useEffect } from "react";
import {
	HelpBlock,
	FormGroup,
	FormControl,
	ControlLabel,
	Checkbox,
	Button,
	ListGroup,
	ListGroupItem,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
// import SignupFormUserCredentials from "../components/SignupFormUserCredentials";
import { useGetApiDataHook } from "../hooks/custom-hook/useGetApiDataHook";
import "./Signup.css";

export default function Signup(props) {
	// constructor(props) {
	//   super(props);

	// const state = {
	//   email: "",
	//   password: "",
	//   confirmPassword: "",
	//   confirmationCode: "",
	//   isCheckboxChecked: false,
	//   missingAirports: "",
	//   requestedTrips: "",
	//   referredBy: ""
	// };
	// }

	// GET origin airports so user can select theirs
	const allAirports = useGetApiDataHook({
		isAuthenticated: props.isAuthenticated,
		isAuthenticationRequired: false,
		// dealType
		// uuid
		requestMethod: "GET",
		requestEndpoint: "originAirports",
		// requestBody
		// requestHeaders
	});

	const [formPage, setFormPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [newUser, setNewUser] = useState(null);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [confirmationCode, setConfirmationCode] = useState("");

	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
	const [isTermsCheckboxChecked, setIsTermsCheckboxChecked] = useState(false);
	const [isSendEmailCheckboxChecked, setIsSendEmailCheckboxChecked] = useState(false);
	const [missingAirports, setMissingAirports] = useState("");
	const [requestedTrips, setRequestedTrips] = useState("");
	const [referredBy, setReferredBy] = useState("");

	const [checkedItems, setCheckedItems] = useState([]);
	const [isFirstRun, setIsFirstRun] = useState(true);

	function presentAirportsAsUi(objects, checked_items_temp_state) {
		// set checkedItems to preCheckedAirports if first run
		let tempCheckedItems = [];

		// if it's the first run
		if (isFirstRun) {
			setIsFirstRun(false);
		}

		// handle changes to the inputs
		const handleChange = (event) => {
			// if the user checked a box
			if (event.target.checked) {
				tempCheckedItems = [...checkedItems, event.target.name];
				setCheckedItems(tempCheckedItems);
			}
			// if the user unchecked a box
			else {
				// filter that array
				tempCheckedItems = checkedItems.filter((item) => item !== event.target.name);
				setCheckedItems(tempCheckedItems);
			}
		};

		// return UI components
		return [{}].concat(objects).map(function (object, i) {
			if (i !== 0) {
				return (
					<ListGroupItem key={i}>
						<input
							type="checkbox"
							onChange={handleChange}
							name={object.airportCode}
							checked={checkedItems.indexOf(object.airportCode) > -1}
						/>
						{" " + object.airportCode + " - " + object.airportName}
					</ListGroupItem>
				);
			}
		});
	}

	function presentLoadingUi() {
		let buttonProps = {
			isLoading: true,
			text: "sometext",
			loadingText: "loadingText",
		};
		return (
			<LoaderButton
				block
				bsSize="large"
				disabled="false"
				type="submit"
				isLoading="true"
				text="Login"
				loadingText="Logging in…"
			/>
		);
	}

	function validateForm(page) {
		let validationEnabled = true; // feature toggle-ish
		if (validationEnabled) {
			switch (page) {
				case 1:
					return email.length > 0 && password.length > 7 && password === confirmPassword;
				case 2:
					return checkedItems.length > 0 || missingAirports.length > 0;
				case 5:
					return (
						email.length > 0 &&
						password.length > 7 &&
						password === confirmPassword &&
						(checkedItems.length > 0 || missingAirports.length > 0) &&
						isTermsCheckboxChecked
					);
				default:
					return true;
			}
		} else {
			return true;
		}
	}

	function validateConfirmationForm() {
		return confirmationCode.length > 0;
	}

	const handleChangeFormPageIncrease = (event) => {
		setFormPage(formPage + 1);
	};

	const handleChangeFormPageDecrease = (event) => {
		setFormPage(formPage - 1);
	};

	async function handleSubmit(event) {
		event.preventDefault();

		setIsLoading(true);

		try {
			const newUser = await Auth.signUp({
				username: email,
				password: password,
				attributes: {
					"custom:airports": checkedItems.join(),
					"custom:requestedAirports": missingAirports,
					"custom:requestedTrips": requestedTrips,
					"custom:referredBy": referredBy,
					"custom:isTermsChecked": isTermsCheckboxChecked === true ? "1" : "0",
					"custom:isDailyEmailChecked": isSendEmailCheckboxChecked === true ? "1" : "0",
				},
			});
			setNewUser(newUser);
		} catch (e) {
			alert(e.message);
		}

		setIsLoading(false);
	}

	async function handleConfirmationSubmit(event) {
		event.preventDefault();

		setIsLoading(true);

		try {
			await Auth.confirmSignUp(email, confirmationCode);
			await Auth.signIn(email, password);

			props.userHasAuthenticated(true);
			props.history.push("/");
		} catch (e) {
			alert(e.message);
			setIsLoading(false);
		}
	}

	const filterAirportsBySearchTerm = (airports, searchTerm) => {
		return airports.filter(
			(data) =>
				data.airportCode.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
				data.airportName.toLowerCase().includes(searchTerm.trim().toLowerCase()),
		);
	};

	function renderConfirmationForm() {
		return (
			<>
				{/*
      <h1 style={{color: 'red'}}>Owner Approval Required</h1>
      <br />
      <p>Registration is by invite only at this time. If you are authorized, contact the site owner so you can be approved.</p>
      */}
				<form onSubmit={handleConfirmationSubmit}>
					<FormGroup controlId="confirmationCode" bsSize="large">
						<ControlLabel>{props.configLang.signupPage.confirmationCodeLabel}</ControlLabel>
						<FormControl
							autoFocus
							type="tel"
							value={confirmationCode}
							onChange={(e) => setConfirmationCode(e.target.value)}
						/>
						<HelpBlock>{props.configLang.signupPage.confirmationCodeHelpTextLabel}</HelpBlock>
					</FormGroup>
					<button
						type="submit"
						className="btn btn-lg btn-block btn-primary"
						disabled={!validateConfirmationForm() || isLoading}
					>
						Verify
					</button>
				</form>
			</>
		);
	}

	function renderForm(formPage, props) {
		// form page 1
		if (formPage == 1) {
			return (
				<>
					<form onSubmit={handleSubmit}>
						<FormGroup controlId="email" bsSize="large">
							<ControlLabel>{props.configLang.signupPage.emailLabel}</ControlLabel>
							<FormControl
								autoFocus
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</FormGroup>
						<FormGroup controlId="password" bsSize="large">
							<ControlLabel>{props.configLang.signupPage.passwordLabel}</ControlLabel>
							<FormControl
								value={password}
								type="password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</FormGroup>
						<FormGroup controlId="confirmPassword" bsSize="large">
							<ControlLabel>{props.configLang.signupPage.confirmPasswordLabel}</ControlLabel>
							<FormControl
								value={confirmPassword}
								type="password"
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
						</FormGroup>
					</form>
					<div className="pageButtonGroup">
						<div>{props.configLang.signupPage.passwordValidationLabel}</div>
						<br />
						<FormGroup controlId="airports" bsSize="large">
							<Button
								onClick={handleChangeFormPageIncrease}
								className="btn btn-success btn-lg"
								disabled={!validateForm(formPage)}
							>
								{props.configLang.commons.nextPage}
							</Button>
						</FormGroup>
					</div>
				</>
			);
		}
		// form page 2
		if (formPage == 2) {
			return (
				<>
					<div className="pageButtonGroup">
						<div>{props.configLang.signupPage.airportValidationLabel}</div>
						<br />
						<FormGroup controlId="airports" bsSize="large">
							<Button onClick={handleChangeFormPageDecrease} className="btn btn-secondary btn-lg">
								{props.configLang.commons.prevPage}
							</Button>
							<Button
								onClick={handleChangeFormPageIncrease}
								className="btn btn-success btn-lg"
								disabled={!validateForm(formPage)}
							>
								{props.configLang.commons.nextPage}
							</Button>
						</FormGroup>
					</div>
					<br />
					<br />
					<form>
						<FormGroup controlId="requestedAirports" bsSize="large">
							<ControlLabel>Search Airports</ControlLabel>
							<FormControl
								autoFocus
								type="text"
								value={missingAirports}
								onChange={(e) => setMissingAirports(e.target.value)}
							/>
						</FormGroup>
						<FormGroup controlId="airports" bsSize="large">
							<ControlLabel>{props.configLang.signupPage.selectedAirportsLabel}</ControlLabel>
							<p className="sectionSubtitle">
								{props.configLang.signupPage.selectedAirportsHelpLabel}
							</p>
							<ListGroup>
								{(allAirports &&
									allAirports.isApiRequestSuccessful &&
									presentAirportsAsUi(
										filterAirportsBySearchTerm(allAirports.data, missingAirports),
										checkedItems,
									)) ||
									presentLoadingUi()}
								{missingAirports.length > 0 && (
									<div>
										<br />
										Click Next to request adding <b>*{missingAirports}*</b> to the list of airports.
										<br />
										<br />
										You can list up to 5 that are not in the list. You cannot have more than 5
										airports total.
									</div>
								)}
							</ListGroup>
						</FormGroup>
					</form>
					<div className="pageButtonGroup">
						<div>{props.configLang.signupPage.airportValidationLabel}</div>
						<br />
						<FormGroup controlId="airports" bsSize="large">
							<Button onClick={handleChangeFormPageDecrease} className="btn btn-secondary btn-lg">
								{props.configLang.commons.prevPage}
							</Button>
							<Button
								onClick={handleChangeFormPageIncrease}
								className="btn btn-success btn-lg"
								disabled={!validateForm(formPage)}
							>
								{props.configLang.commons.nextPage}
							</Button>
						</FormGroup>
					</div>
				</>
			);
		}
		// form page 3
		if (formPage == 3) {
			return (
				<>
					<form>
						<FormGroup controlId="requestedTrips" bsSize="large">
							<ControlLabel>{props.configLang.signupPage.preferredTripsLabel}</ControlLabel>
							<FormControl
								autoFocus
								type="text"
								value={requestedTrips}
								onChange={(e) => setRequestedTrips(e.target.value)}
							/>
						</FormGroup>
					</form>
					<div className="pageButtonGroup">
						<div>{props.configLang.signupPage.optionalLabel}</div>
						<br />
						<FormGroup controlId="airports" bsSize="large">
							<Button onClick={handleChangeFormPageDecrease} className="btn btn-secondary btn-lg">
								{props.configLang.commons.prevPage}
							</Button>
							<Button
								onClick={handleChangeFormPageIncrease}
								className="btn btn-success btn-lg"
								disabled={!validateForm(formPage)}
							>
								{props.configLang.commons.nextPage}
							</Button>
						</FormGroup>
					</div>
				</>
			);
		}
		// form page 4
		if (formPage == 4) {
			return (
				<>
					<form>
						<FormGroup controlId="referredBy" bsSize="large">
							<ControlLabel>{props.configLang.signupPage.referredByLabel}</ControlLabel>
							<FormControl
								autoFocus
								type="text"
								value={referredBy}
								onChange={(e) => setReferredBy(e.target.value)}
							/>
						</FormGroup>
					</form>
					<div className="pageButtonGroup">
						<div>{props.configLang.signupPage.optionalLabel}</div>
						<br />
						<FormGroup controlId="airports" bsSize="large">
							<Button onClick={handleChangeFormPageDecrease} className="btn btn-secondary btn-lg">
								{props.configLang.commons.prevPage}
							</Button>
							<Button
								onClick={handleChangeFormPageIncrease}
								className="btn btn-success btn-lg"
								disabled={!validateForm(formPage)}
							>
								{props.configLang.commons.nextPage}
							</Button>
						</FormGroup>
					</div>
				</>
			);
		}
		// form page 5 (confirm and submit)
		else {
			return (
				<>
					<form>
						<div>
							<h2>{props.configLang.signupPage.confirmInfoLabel}</h2>
							<p>
								{props.configLang.signupPage.emailLabel} {email}
							</p>
							{/* Only display these if they aren't blank */}
							<p>
								{checkedItems.length < 1
									? ""
									: props.configLang.signupPage.selectedAirportsLabel +
									  checkedItems.join().replace(/,/g, ", ")}
							</p>
							<p>
								{missingAirports.length < 1
									? ""
									: props.configLang.signupPage.missingAirportsLabel + missingAirports}
							</p>
							<p>
								{requestedTrips.length < 1
									? ""
									: props.configLang.signupPage.requestedTripsLabel + requestedTrips}
							</p>
							<p>
								{referredBy.length < 1
									? ""
									: props.configLang.signupPage.referredByLabel + referredBy}
							</p>
						</div>
						<h2>{props.configLang.signupPage.termsAndConditionsHeaderLabel}</h2>
						<FormGroup className="checkbox">
							<Checkbox
								checked={isTermsCheckboxChecked}
								onChange={(e) => setIsTermsCheckboxChecked(!isTermsCheckboxChecked)}
							>
								{props.configLang.signupPage.termsAndConditionsCheckboxStatement}
								<a
									href="http://cheapflightrabbit.s3-website-us-east-1.amazonaws.com/terms.html"
									target="_blank"
								>
									{props.configLang.signupPage.termsAndConditionsCheckboxLabel}
								</a>
							</Checkbox>
						</FormGroup>
						<FormGroup className="checkbox">
							<Checkbox
								checked={isSendEmailCheckboxChecked}
								onChange={(e) => setIsSendEmailCheckboxChecked(!isSendEmailCheckboxChecked)}
							>
								{props.configLang.signupPage.dailyEmailCheckboxLabel}
							</Checkbox>
						</FormGroup>
					</form>
					<br />
					<div className="pageButtonGroup">
						<FormGroup controlId="airports" bsSize="large">
							<Button onClick={handleChangeFormPageDecrease} className="btn btn-secondary btn-lg">
								{props.configLang.commons.prevPage}
							</Button>
							<Button
								onClick={handleSubmit}
								className="btn btn-success btn-lg"
								disabled={!validateForm(formPage) || isLoading}
							>
								{props.configLang.signupPage.confirmButtonLabel}
							</Button>
							<br />
							<br />
						</FormGroup>
					</div>
				</>
			);
		}
	}

	return (
		<div className="Signup">
			{newUser === null && formPage ? renderForm(formPage, props) : renderConfirmationForm()}
		</div>
	);
}
